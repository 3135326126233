// src/components/RealTimeFlowExecution.js

import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { executionUpdated } from '../slices/executionsSlice';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { Progress } from './ui/progress';
import { Card, CardContent } from './ui/card';
import { SocketContext } from '../App';

const RealTimeFlowExecution = ({ flowId, steps, isPolling, setIsPolling }) => {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);  // Use the SocketContext
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepStatuses, setStepStatuses] = useState([]);

  useEffect(() => {
    if (steps) {
      setStepStatuses(steps.map(() => ({ status: 'pending' })));
    }
  }, [steps]);

  useEffect(() => {
    if (!socket) {
      return;
    }


    socket.on('execution:started', (data) => {
      if (data.flowId === flowId) {
        setStatus('running');
        setProgress(0);
        setCurrentStep(0);
        setStepStatuses(steps.map(() => ({ status: 'pending' })));
        dispatch(executionUpdated({ ...data, status: 'running', progress: 0 }));
      }
    });

    socket.on('step:started', (data) => {
      if (data.flowId === flowId) {
        setCurrentStep(data.stepNumber);
        setStepStatuses(prevStatuses => {
          const newStatuses = [...prevStatuses];
          newStatuses[data.stepNumber - 1] = { status: 'running' };
          return newStatuses;
        });
      }
    });

    socket.on('step:completed', (data) => {
      if (data.flowId === flowId) {
        setCurrentStep(data.stepNumber);
        const newProgress = (data.stepNumber / steps.length) * 100;
        setProgress(newProgress);
        setStepStatuses(prevStatuses => {
          const newStatuses = [...prevStatuses];
          newStatuses[data.stepNumber - 1] = { status: 'completed', output: data.output };
          return newStatuses;
        });
        dispatch(executionUpdated({ ...data, status: 'running', progress: newProgress }));
      }
    });

    socket.on('execution:completed', (data) => {
      if (data.flowId === flowId) {
        setStatus('completed');
        setProgress(100);
        dispatch(executionUpdated({ ...data, status: 'completed', progress: 100 }));
        setIsPolling(false);
      }
    });

    socket.on('execution:failed', (data) => {
      if (data.flowId === flowId) {
        setStatus('failed');
        setError(data.error);
        setStepStatuses(prevStatuses => {
          const newStatuses = [...prevStatuses];
          newStatuses[data.stepNumber - 1] = { status: 'failed', error: data.error };
          return newStatuses;
        });
        dispatch(executionUpdated({ ...data, status: 'failed', error: data.error }));
        setIsPolling(false);
      }
    });

    return () => {
      socket.off('execution:started');
      socket.off('step:started');
      socket.off('step:completed');
      socket.off('execution:completed');
      socket.off('execution:failed');
    };
  }, [socket, flowId, dispatch, steps, setIsPolling]);

  if (status === 'idle') {
    return null;
  }

  return (
    <div className="mt-4">
      <h2 className="text-xl font-bold mb-2">Execution Status</h2>
      <Progress value={progress} className="w-full" />
      <p className="mt-2">Status: {status}</p>
      <p>Current Step: {currentStep} / {steps.length}</p>
      {error && (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      <div className="mt-4">
        <h3 className="text-lg font-bold mb-2">Step Details</h3>
        {steps.map((step, index) => (
          <Card key={index} className="mb-2">
            <CardContent className="p-4">
              <h4 className="font-bold">{index + 1}. {step.name} ({step.type})</h4>
              <p>Status: {stepStatuses[index]?.status}</p>
              {stepStatuses[index]?.status === 'completed' && (
                <pre className="mt-2 p-2 bg-gray-100 rounded">
                  {JSON.stringify(stepStatuses[index]?.output, null, 2)}
                </pre>
              )}
              {stepStatuses[index]?.status === 'failed' && (
                <Alert variant="destructive" className="mt-2">
                  <AlertDescription>{stepStatuses[index]?.error}</AlertDescription>
                </Alert>
              )}
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default RealTimeFlowExecution;