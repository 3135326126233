import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import { fetchFlows } from "../slices/flowsSlice";
import { fetchExecutions } from "../slices/executionsSlice";
import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
  CardFooter,
} from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Activity, GitBranch, Play, AlertTriangle, Users, ExternalLink, Plus, List, Clock } from "lucide-react";
import { formatDistanceToNow } from 'date-fns';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { flows } = useSelector((state) => state.flows);
  const executionsState = useSelector((state) => state.executions);
  const { selectedTeam } = useSelector((state) => state.teams);
  const { teams } = useSelector((state) => state.teams);
  const [error, setError] = useState(null);
  const [filteredExecutions, setFilteredExecutions] = useState([]);
  const [statusFilter, setStatusFilter] = useState("all");
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(fetchFlows({ page: 1, limit: 10, teamId: selectedTeam })).unwrap(),
          dispatch(fetchExecutions({ 
            page: 1, 
            limit: 1000,
            teamId: selectedTeam, 
            sortBy: 'startTime', 
            sortOrder: 'desc' 
          })).unwrap(),
        ]);
      } catch (err) {
        setError("Failed to fetch dashboard data. Please try again later.");
        console.error("Error fetching data:", err);
      }
    };
    fetchData();
  }, [dispatch, selectedTeam]);

  useEffect(() => {
    if (executionsState.executions && executionsState.executions.length > 0) {
      const filtered = statusFilter === "all"
        ? executionsState.executions.slice(0, 20)
        : executionsState.executions.filter(execution => execution.status === statusFilter).slice(0, 20);
      setFilteredExecutions(filtered);

      const executionStatusCounts = executionsState.executions.reduce((acc, execution) => {
        acc[execution.status] = (acc[execution.status] || 0) + 1;
        return acc;
      }, {});

      setChartData([
        { id: "Completed", label: "Completed", value: executionStatusCounts.completed || 0 },
        { id: "Failed", label: "Failed", value: executionStatusCounts.failed || 0 },
        { id: "Running", label: "Running", value: executionStatusCounts.running || 0 },
      ]);
    }
  }, [executionsState.executions, statusFilter]);

  const handleStatusFilterChange = (value) => {
    setStatusFilter(value);
  };

  const handleViewResults = (executionId) => {
    navigate(`/execution/${executionId}`, { state: { viewingPrevious: true } });
  };

  const activeFlows = flows.filter((flow) => flow.isActive).length;

  const softColors = ['#68d391', '#fc8181', '#fbd38d'];

  const getColor = (bar) => {
    const colors = {
      Completed: softColors[0],
      Failed: softColors[1],
      Running: softColors[2]
    };
    return colors[bar.data.id] || softColors[0];
  };

  const MemoizedBarChart = useMemo(() => {
    return () => (
      <ResponsiveBar
        data={chartData}
        keys={['value']}
        indexBy="id"
        margin={{ top: 10, right: 10, bottom: 40, left: 80 }}
        padding={0.3}
        layout="horizontal"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={getColor}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    );
  }, [chartData]);

  // Memoized PieChart component
  const MemoizedPieChart = useMemo(() => {
    return () => (
      <ResponsivePie
        data={chartData}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={getColor}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextColor="#333333"
        radialLabelsLinkColor={{ from: 'color' }}
        sliceLabelsSkipAngle={10}
        sliceLabelsTextColor="#333333"
      />
    );
  }, [chartData]);

  /*if (executionsState.status === 'loading') {
    return <div>Loading dashboard data...</div>;
  }*/

  if (executionsState.status === 'failed') {
    return <div>Error loading dashboard data: {executionsState.error}</div>;
  }

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold text-inter">Dashboard</h1>
      {error && (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Flows</CardTitle>
            <GitBranch className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{flows.length}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Active Flows</CardTitle>
            <Activity className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{activeFlows}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Executions</CardTitle>
            <Play className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{executionsState.totalExecutions || 0}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Failed Executions</CardTitle>
            <AlertTriangle className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {chartData.find(item => item.id === "Failed")?.value || 0}
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Teams</CardTitle>
            <Users className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{teams ? teams.length : 0}</div>
          </CardContent>
        </Card>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <Card className="lg:col-span-1">
          <CardHeader>
            <CardTitle className="text-inter font-bold">Execution Status Overview</CardTitle>
          </CardHeader>
          <CardContent className="h-[400px]">
            <div className="grid grid-cols-2 gap-4 h-full">
            <div className="col-span-1 h-full">
                <MemoizedBarChart />
              </div>
              <div className="col-span-1 h-full">
                <MemoizedPieChart />
              </div>
            </div>
          </CardContent>
        </Card>
        <Card className="lg:col-span-1 flex flex-col h-[500px]">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-base">Recent Executions</CardTitle>
            <Select onValueChange={handleStatusFilterChange} defaultValue="all">
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Filter by status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All</SelectItem>
                <SelectItem value="completed">Completed</SelectItem>
                <SelectItem value="failed">Failed</SelectItem>
                <SelectItem value="pending">Pending</SelectItem>
              </SelectContent>
            </Select>
          </CardHeader>
          <CardContent className="flex-grow overflow-hidden">
            <div className="h-full overflow-y-auto pr-2">
              <ul className="space-y-4">
                {filteredExecutions.length > 0 ? (
                  filteredExecutions.map((execution) => (
                    <li
                      key={execution._id}
                      className="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center bg-gray-50 p-4 rounded-lg shadow-sm"
                    >
                      <div className="flex flex-col mb-2 sm:mb-0">
                        <span className="font-semibold text-gray-800">{execution.flow.name}</span>
                        <span className="text-sm text-gray-500">
                          {formatDistanceToNow(new Date(execution.startTime), { addSuffix: true })}
                        </span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <span
                          className={`px-3 py-1 rounded-full text-xs font-medium ${
                            execution.status === "completed"
                              ? "bg-green-100 text-green-800"
                              : execution.status === "failed"
                              ? "bg-red-100 text-red-800"
                              : "bg-yellow-100 text-yellow-800"
                          }`}
                        >
                          {execution.status}
                        </span>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => handleViewResults(execution._id)}
                          className="text-gray-600 hover:text-gray-800 transition-colors duration-200"
                        >
                          <ExternalLink className="h-4 w-4 mr-2" />
                          View
                        </Button>
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="text-center text-gray-500">No executions found.</li>
                )}
              </ul>
            </div>
          </CardContent>
          <CardFooter className="mt-auto grid grid-cols-3 gap-2">
            <NavLink to="/create-flow" className="w-full">
              <Button variant="default" className="w-full bg-gray-900 text-white hover:bg-gray-800">
                <Plus className="mr-2 h-4 w-4" />
                Create Flow
              </Button>
            </NavLink>
            <NavLink to="/manage-flows" className="w-full">
              <Button variant="default" className="w-full bg-gray-900 text-white hover:bg-gray-800">
                <List className="mr-2 h-4 w-4" />
                Manage Flows
              </Button>
            </NavLink>
            <NavLink to="/scheduling" className="w-full">
              <Button variant="default" className="w-full bg-gray-900 text-white hover:bg-gray-800">
                <Clock className="mr-2 h-4 w-4" />
                Scheduling
              </Button>
            </NavLink>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;