import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotifications } from '../slices/notificationsSlice';
import { logout } from '../slices/authSlice';
import { cn } from "../lib/utils";
import { Button } from "../components/ui/button";
import { Home, Globe, Plus, List, Clock, LogOut, Users, ChevronLeft, ChevronRight, Inbox, FileText, Share2, Settings, Image, BotMessageSquare, ImagePlus, GitBranchPlus, ChevronDown, ChevronUp } from 'lucide-react';
import TeamSelector from '../components/TeamSelector';
import { fetchAppFeatures } from '../slices/appFeaturesSlice';
import { MagicWandIcon } from '@radix-ui/react-icons';

const Layout = ({ children }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [sectionStates, setSectionStates] = useState({
        management: true,
        workspace: true,
        savedAssets: true,
        admin: false
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { notifications } = useSelector((state) => state.notifications);
    const { isAuthenticated } = useSelector((state) => state.auth);

    const fetchNotificationsCallback = useCallback(() => {
        dispatch(fetchNotifications());
    }, [dispatch]);

    useEffect(() => {
        if (isAuthenticated) {
            fetchNotificationsCallback();
            // Uncomment the next line if you want to fetch app features as well
            // dispatch(fetchAppFeatures());
        }
    }, [isAuthenticated, fetchNotificationsCallback]);

    const unreadCount = notifications.filter(n => !n.isRead && !n.isArchived).length;

    const handleLogout = async () => {
        try {
            await dispatch(logout()).unwrap();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const toggleSection = (section) => {
        setSectionStates(prev => ({ ...prev, [section]: !prev[section] }));
    };

    const SectionHeader = ({ text, section, isOpen }) => (
        <button
            onClick={() => toggleSection(section)}
            className={cn(
                "w-full flex items-center justify-between px-2 py-1 text-xs font-semibold text-gray-500 dark:text-gray-400 uppercase tracking-wider hover:bg-gray-100 dark:hover:bg-gray-700",
                isCollapsed && "sr-only"
            )}
        >
            {text}
            {isOpen ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
        </button>
    );

    const SectionContent = ({ children, isOpen }) => (
        isOpen ? <div className="p-2">{children}</div> : null
    );

    return (
        <div className="flex h-screen overflow-hidden">
            <aside className={cn(
                "flex flex-col bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 transition-all duration-300",
                isCollapsed ? "w-16" : "w-64"
            )}>
                {!isCollapsed && <TeamSelector />}
                <div className="flex-1 overflow-y-auto">
                    <SectionHeader text="Management" section="management" isOpen={sectionStates.management} />
                    <SectionContent isOpen={sectionStates.management}>
                        <nav className="flex flex-col gap-1">
                            <NavLink to="/" icon={<Home className="h-4 w-4" />} text="Dashboard" isCollapsed={isCollapsed} />
                            <NavLink to="/create-flow" icon={<Plus className="h-4 w-4" />} text="Create Flow" isCollapsed={isCollapsed} />
                            <NavLink to="/manage-flows" icon={<List className="h-4 w-4" />} text="Manage Flows" isCollapsed={isCollapsed} />
                            <NavLink to="/execution" icon={<GitBranchPlus className="h-4 w-4" />} text="Executions" isCollapsed={isCollapsed} />
                            <NavLink to="/scheduling" icon={<Clock className="h-4 w-4" />} text="Scheduling" isCollapsed={isCollapsed} />
                        </nav>
                    </SectionContent>

                    <div className="mx-2 my-2 border-t border-gray-200 dark:border-gray-700" />
                    <SectionHeader text="Workspace" section="workspace" isOpen={sectionStates.workspace} />
                    <SectionContent isOpen={sectionStates.workspace}>
                        <nav className="flex flex-col gap-1">
                            <NavLink to="/chat" icon={<BotMessageSquare className="h-4 w-4" />} text="Chat" isCollapsed={isCollapsed} />
                            <NavLink to="/image-generation" icon={<ImagePlus className="h-4 w-4" />} text="Generate Images" isCollapsed={isCollapsed} />
                            <NavLink to="/custom-assistants" icon={<MagicWandIcon className="h-4 w-4" />} text="Custom Assistants" isCollapsed={isCollapsed} />
                        </nav>
                    </SectionContent>

                    <div className="mx-2 my-2 border-t border-gray-200 dark:border-gray-700" />
                    <SectionHeader text="Saved Assets" section="savedAssets" isOpen={sectionStates.savedAssets} />
                    <SectionContent isOpen={sectionStates.savedAssets}>
                        <nav className="flex flex-col gap-1">
                        <NavLink 
    to="/assets/inbox" 
    icon={<Inbox className="h-4 w-4" />} 
    text="Inbox" 
    isCollapsed={isCollapsed}
    badge={unreadCount > 0 ? unreadCount : null}
/>
                            <NavLink to="/assets/blog-posts" icon={<FileText className="h-4 w-4" />} text="Blog Posts" isCollapsed={isCollapsed} />
                            <NavLink to="/assets/social-posts" icon={<Share2 className="h-4 w-4" />} text="Social Posts" isCollapsed={isCollapsed} />
                            <NavLink to="/assets/general-assets" icon={<Globe className="h-4 w-4" />} text="General" isCollapsed={isCollapsed} />
                            <NavLink to="/assets/general-images" icon={<Image className="h-4 w-4" />} text="Images" isCollapsed={isCollapsed} />
                        </nav>
                    </SectionContent>

                    <div className="mx-2 my-2 border-t border-gray-200 dark:border-gray-700" />
                    <SectionHeader text="Admin" section="admin" isOpen={sectionStates.admin} />
                    <SectionContent isOpen={sectionStates.admin}>
                        <nav className="flex flex-col gap-1">
                            <NavLink to="/manage-teams" icon={<Users className="h-4 w-4" />} text="Manage Teams" isCollapsed={isCollapsed} />
                            <NavLink to="/privacy-policy" icon={<Users className="h-4 w-4" />} text="Privacy Policy" isCollapsed={isCollapsed} />
                            <NavLink to="/terms-of-service" icon={<Users className="h-4 w-4" />} text="Terms of Service" isCollapsed={isCollapsed} />
                            <NavLink to="/data-deletion" icon={<Users className="h-4 w-4" />} text="Data Deletion" isCollapsed={isCollapsed} />
                        </nav>
                    </SectionContent>
                </div>
                <Button 
                    variant="ghost" 
                    onClick={handleLogout}
                    className={cn(
                        "m-2 justify-start font-semibold text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700",
                        isCollapsed && "justify-center"
                    )}
                >
                    <LogOut className={cn("h-5 w-5", isCollapsed ? "mr-0" : "mr-2")} />
                    {!isCollapsed && "Logout"}
                </Button>
                <div className="border-t border-gray-200 dark:border-gray-700">
                    <Button 
                        variant="ghost" 
                        onClick={toggleSidebar}
                        className="w-full p-2 flex items-center justify-between text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                        {!isCollapsed && <span>Collapse Sidebar</span>}
                        {isCollapsed ? <ChevronRight size={20} /> : <ChevronLeft size={20} />}
                    </Button>
                </div>
                <div className="flex items-center h-10 px-4 bg-gray-900 text-white rounded-md border border-gray-200">
                    {!isCollapsed && (
                        <>
                            <img src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/flowOrchestraIcon.png" alt="Flow Orchestra" className="h-8 w-8 mr-2" />
                            <h1 className="text-lg font-semibold">Flow Orchestra</h1>
                        </>
                    )}
                </div>
            </aside>
            <main className="flex-1 overflow-y-auto bg-gray-100 dark:bg-gray-900">
                <div className="p-6 pt-1">
                    {children}
                </div>
            </main>
        </div>
    );
};

const NavLink = ({ to, icon, text, isCollapsed, badge }) => {
    const location = useLocation();
    const isActive = 
        (to === '/chat' && location.pathname.startsWith('/chat')) ||
        (to === '/execution' && location.pathname.startsWith('/execution')) ||
        location.pathname === to;

    return (
        <Button
            asChild
            variant="ghost"
            className={cn(
                "w-full justify-start font-semibold text-gray-700 dark:text-gray-300",
                isActive 
                    ? "bg-gray-900 text-white hover:bg-gray-800 hover:text-white" 
                    : "hover:bg-gray-200 dark:hover:bg-gray-700",
                isCollapsed && "justify-center"
            )}
        >
            <Link to={to} className="flex items-center w-full">
                    <span className={cn("flex-shrink-0", isCollapsed ? "mr-0" : "mr-3")}>{icon}</span>
                    {!isCollapsed && (
                        <>
                            <span className="whitespace-nowrap">{text}</span>
                            {badge && (
                                <span className="ml-auto bg-gray-400 text-white text-xs font-bold px-2 py-1 rounded-full">
                                    {badge}
                                </span>
                            )}
                        </>
                    )}
                </Link>
        </Button>
    );
};

export default Layout;