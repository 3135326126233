//src/pages/FlowFormComponents/stepTypes.js

export const GenerateStepType = {
  API_CALL: 'apiCall',
  TRANSFORMATION: 'transformation',
  CONDITIONAL: 'conditional',
  LOOP: 'loop',
  AI_REQUEST: 'aiRequest',
  AI_CHECK_AND_IMPROVE: 'aiCheckAndImprove',
  AI_CONDITIONAL_CHECKER: 'aiConditionalChecker',
  GENERATE_AI_IMAGE: 'generateAIImage',
  ADD_CONTEXT: 'addContext',
};

export const ActionStepType = {
  POST_TO_WORDPRESS: 'postToWordpress',
  SAVE_ASSET: 'saveAsset',
  POST_TO_INSTAGRAM: 'postToInstagram',
};

export const getStepInstructions = (stepType) => {
  const outputKeyInstructions = "Provide an output key to store this step's result. This key can be used to access the step's output in subsequent steps.";
 
  switch (stepType) {
    case GenerateStepType.API_CALL:
      return `Make an HTTP request to an external API. Specify the URL, method (GET, POST, PUT, DELETE), headers, and body of the request. ${outputKeyInstructions} For example, use 'userData' to store user data fetched from an API.`;
    case GenerateStepType.TRANSFORMATION:
      return `Transform data using JavaScript code. You have access to the 'context' object, which contains results from previous steps. Write a function that takes 'context' as an argument and returns the transformed data. ${outputKeyInstructions} For example, use 'processedData' to store the result of your data processing.`;
    case GenerateStepType.CONDITIONAL:
      return `Execute different steps based on a condition. Specify a condition using JavaScript (returning true or false), and provide step numbers for true and false cases. The condition has access to the 'context' object. ${outputKeyInstructions} For conditionals, you might use 'conditionResult' as the output key.`;
    case GenerateStepType.LOOP:
      return `Repeat a series of steps a specified number of times or while a condition is true. For conditional loops, specify a condition using JavaScript (returning true or false) and the step number to jump to if the condition is true. The loop has access to the 'context' object. ${outputKeyInstructions} For loops, you could use 'loopResult' as the output key.`;
    case GenerateStepType.GENERATE_AI_IMAGE:
      return `Generate an AI image using DALL-E. Specify the image prompt and size. ${outputKeyInstructions} For example, use 'generatedImage' to store the URL of the generated image.`;
    case GenerateStepType.ADD_CONTEXT:
      return `Add additional context to the conversation thread. This context will be available for use in subsequent steps. ${outputKeyInstructions} For example, use 'additionalContext' as the output key.`;
    case GenerateStepType.AI_REQUEST:
      return `Send a prompt to a selected AI assistant and receive a response. Choose an AI assistant and provide a prompt for it to process. ${outputKeyInstructions} For example, use 'aiResponse' to store the assistant's response.`;
    case GenerateStepType.AI_CHECK_AND_IMPROVE:
      return `This step checks the response from the previous step to ensure its correctness and make any necessary improvements. It uses an AI assistant to review and enhance the content. ${outputKeyInstructions} For example, use 'improvedResponse' to store the checked and improved content.`;
    case GenerateStepType.AI_CONDITIONAL_CHECKER:
      return `Analyze the conversation thread to determine if the most recent response achieves the requested result. Respond with a JSON object containing an 'approved' key with a boolean value. ${outputKeyInstructions} For example, use 'conditionalResult' to store the approval status.`;    
    case ActionStepType.SAVE_ASSET:
      return `Save an asset to the database. Choose the asset type and provide the necessary data. ${outputKeyInstructions} You are unlikely to use the output for this step.`;
    case ActionStepType.POST_TO_WORDPRESS:
      return `Post content to a WordPress site. Specify the WordPress site URL, post title, content, and other metadata like categories and tags. ${outputKeyInstructions} For example, use 'wordpressPostId' to store the ID of the created WordPress post.`;
    case ActionStepType.POST_TO_INSTAGRAM:
      return `Post content to Instagram. Specify the image or video file, caption, and other metadata like hashtags and mentions. ${outputKeyInstructions} For example, use 'instagramPostId' to store the ID of the created Instagram post.`;
    default:
      return "Instructions not available for this step type.";
  }
};