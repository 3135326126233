import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../services/api';

export const fetchSavedAssets = createAsyncThunk(
  'savedAssets/fetchSavedAssets',
  async (assetType, { rejectWithValue }) => {
    try {
      const response = await api.fetchSavedAssets(assetType);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteSavedAsset = createAsyncThunk(
  'savedAssets/deleteSavedAsset',
  async ({ assetType, id }, { rejectWithValue }) => {
    try {
      await api.deleteSavedAsset(assetType, id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const savedAssetsSlice = createSlice({
  name: 'savedAssets',
  initialState: {
    assets: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSavedAssets.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchSavedAssets.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.assets = action.payload;
      })
      .addCase(fetchSavedAssets.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteSavedAsset.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(deleteSavedAsset.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.assets = state.assets.filter(asset => asset._id !== action.payload);
      })
      .addCase(deleteSavedAsset.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default savedAssetsSlice.reducer;