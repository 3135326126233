import React, { useState } from 'react';
import { Button } from './ui/button';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from './ui/alert-dialog';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWordpress } from '@fortawesome/free-brands-svg-icons';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { postToInstagram, postToWordpress, saveAsset } from '../services/api';
import { ActionStepType } from '../pages/FlowFormComponents/stepTypes';
import { useToast } from './ui/use-toast';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const ActionButton = ({ actionType, id }) => {
  const { toast } = useToast();
  const [isExpanded, setIsExpanded] = useState(false);
  const [wordpressSiteUrl, setWordpressSiteUrl] = useState('https://thatnurselife.com');
  const [postStatus, setPostStatus] = useState('draft');
  const [publishDate, setPublishDate] = useState(null);

  const getActionConfig = () => {
    switch (actionType) {
      case ActionStepType.POST_TO_INSTAGRAM:
        return {
          icon: faInstagram,
          text: 'Post to Instagram',
          color: 'bg-pink-500 hover:bg-pink-600',
          action: () => postToInstagram(id)
        };
      case ActionStepType.POST_TO_WORDPRESS:
        return {
          icon: faWordpress,
          text: 'Post to WordPress',
          color: 'bg-green-500 hover:bg-green-600',
          action: () => handleWordPressPost()
        };
      case ActionStepType.SAVE_ASSET:
        return {
          icon: faSave,
          text: 'Save Asset',
          color: 'bg-green-500 hover:bg-green-600',
          action: () => saveAsset(id)
        };
      default:
        return null;
    }
  };

  const config = getActionConfig();

  if (!config) return null;

  const handleWordPressPost = async () => {
    try {
      await postToWordpress(id, {
        wordpressSiteUrl,
        postStatus,
        date: publishDate ? publishDate.toISOString() : null
      });
      toast({
        title: "Success!",
        description: `Post submitted to WordPress successfully.`,
        variant: "success",
      });
      setIsExpanded(false);
    } catch (error) {
      console.error('Error posting to WordPress:', error);
      toast({
        title: "Error",
        description: `Failed to post to WordPress. Please try again.`,
        variant: "destructive",
      });
    }
  };

  const handleAction = async () => {
    if (actionType === ActionStepType.POST_TO_WORDPRESS) {
      setIsExpanded(true);
    } else {
      try {
        await config.action();
        toast({
          title: "Success!",
          description: `${config.text} action completed successfully.`,
          variant: "success",
        });
      } catch (error) {
        console.error('Error performing action:', error);
        toast({
          title: "Error",
          description: `Failed to ${config.text.toLowerCase()}. Please try again.`,
          variant: "destructive",
        });
      }
    }
  };

  const renderWordPressForm = () => (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">WordPress Post Settings</h3>
        <Button onClick={() => setIsExpanded(false)} variant="ghost" size="sm">
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">WordPress Site</label>
          <Select value={wordpressSiteUrl} onValueChange={setWordpressSiteUrl}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select WordPress site" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="https://thatnurselife.com">That Nurse Life</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Post Status</label>
          <Select value={postStatus} onValueChange={setPostStatus}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select post status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="draft">Draft</SelectItem>
              <SelectItem value="publish">Publish</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Publish Date (Optional)</label>
          <DatePicker
            selected={publishDate}
            onChange={(date) => setPublishDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm aa"
            className="w-full p-2 border rounded-md"
            placeholderText="Select date and time"
          />
        </div>
        <Button onClick={handleWordPressPost} className={`${config.color} w-full`}>
          Submit to WordPress
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      {!isExpanded ? (
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button onClick={handleAction} className={`${config.color} text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105`}>
              <FontAwesomeIcon icon={config.icon} className="mr-2 h-5 w-5" />
              {config.text}
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Confirm Action</AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to {config.text.toLowerCase()}? This action cannot be undone.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={handleAction}>Confirm</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      ) : (
        renderWordPressForm()
      )}
    </div>
  );
};

export default ActionButton;