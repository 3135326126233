// src/pages/FlowFormComponents/ActionStepFields.js

import React, { useEffect } from 'react';
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Textarea } from "../../components/ui/textarea";
import { Card, CardContent } from "../../components/ui/card";
import { RadioGroup, RadioGroupItem } from "../../components/ui/radio-group";
import { ActionStepType } from './stepTypes';
import { SimplifiedMultiSelect } from "../../components/ui/simplified-multi-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from "date-fns";

const authors = [
  { value: "3", label: "Helena Hargrove" },
  { value: "4", label: "Jason Thompson" },
  { value: "5", label: "Amy Tannenbaum" }
];

const categories = [
  { value: 39, label: "Education" },
  { value: 40, label: "Entertainment" },
  { value: 78, label: "Entertainment > Self-Care" }
];

const savableAssets = [
  { value: "generalImage", label: "General Image" },
  { value: "generalText", label: "General Text" },
  { value: "blogPost", label: "Blog Post" },
  { value: "socialPost", label: "Social Post" }
];

const tags = [
  { value: 105, label: "Gifts For Nurses" },
  { value: 45, label: "Health Promotion" },
  { value: 84, label: "Holidays" },
  { value: 108, label: "NCLEX Tips" },
  { value: 106, label: "Nurse Fashion" },
  { value: 90, label: "Nurse Hacks" },
  { value: 79, label: "Nurse Humor" },
  { value: 81, label: "Nurse Life" },
  { value: 75, label: "Nurse Self-Care" },
  { value: 107, label: "Nurse Shirts" },
  { value: 76, label: "Nursing Lifestyle" },
  { value: 88, label: "Nursing Rituals" },
  { value: 89, label: "Nursing Tips" }
];

const ActionStepFields = React.memo(({ step, onStepChange, index }) => {
  useEffect(() => {
    // Set default values if they're not already set
    if (step.type === ActionStepType.POST_TO_WORDPRESS) {
      if (!step.author) {
        onStepChange('author', authors[0].value);
      }
      if (!step.postStatus) {
        onStepChange('postStatus', 'draft');
      }
      if (!step.inputMode) {
        onStepChange('inputMode', 'standard');
      }
    } else if (step.type === ActionStepType.SAVE_ASSET) {
      if (!step.assetType) {
        onStepChange('assetType', savableAssets[0].value);
      }
    }
  }, [step.type]);

  const handleFieldChange = (field, value) => {
    onStepChange(field, value);
  };

  const handleDateChange = (date) => {
    if (date) {
      handleFieldChange('scheduledAt', format(date, "yyyy-MM-dd'T'HH:mm:ss"));
    } else {
      handleFieldChange('scheduledAt', null);
    }
  };

  const scheduledDate = step.scheduledAt 
    ? parse(step.scheduledAt, "yyyy-MM-dd'T'HH:mm:ss", new Date()) 
    : null;


    const renderPostToWordPressFields = () => {
  return (
    <Card>
      <CardContent className="pt-6 space-y-4">
        <div>
          <Label htmlFor="wordpress-site-url">WordPress Site URL</Label>
          <select
            id="wordpress-site-url"
            value={step.wordpressSiteUrl || ''}
            onChange={(e) => handleFieldChange('wordpressSiteUrl', e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Select WordPress Site URL</option>
            <option value="https://thatnurselife.com">https://thatnurselife.com</option>
          </select>
        </div>

        <div>
          <Label htmlFor="output-key">Output Key</Label>
          <Input
            id="output-key"
            placeholder="e.g., wordpressPostId"
            value={step.outputKey || ''}
            onChange={(e) => handleFieldChange('outputKey', e.target.value)}
            required
          />
        </div>

        <div>
          <Label>Input Mode</Label>
          <RadioGroup
            value={step.inputMode || 'standard'}
            onValueChange={(value) => handleFieldChange('inputMode', value)}
            className="flex space-x-4"
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="standard" id="standard" />
              <Label htmlFor="standard">Standard</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="json" id="json" />
              <Label htmlFor="json">JSON</Label>
            </div>
          </RadioGroup>
        </div>

        {(step.inputMode || 'standard') === 'standard' ? (
          <>
            <div>
              <Label htmlFor="post-title">Post Title</Label>
              <Input
                id="post-title"
                placeholder="Enter post title"
                value={step.postTitle || ''}
                onChange={(e) => handleFieldChange('postTitle', e.target.value)}
                required
              />
            </div>

            <div>
              <Label htmlFor="post-content">Post Content</Label>
              <Textarea
                id="post-content"
                placeholder="Enter post content (can use placeholders like {{previousStep}})"
                value={step.postContent || ''}
                onChange={(e) => handleFieldChange('postContent', e.target.value)}
                className="min-h-[200px]"
                required
              />
            </div>

            <div>
              <Label htmlFor="excerpt">Excerpt</Label>
              <Textarea
                id="excerpt"
                placeholder="Enter post excerpt"
                value={step.excerpt || ''}
                onChange={(e) => handleFieldChange('excerpt', e.target.value)}
              />
            </div>

            <div>
              <Label htmlFor="author">Author</Label>
              <select
                id="author"
                value={step.author || ''}
                onChange={(e) => handleFieldChange('author', e.target.value)}
                className="w-full p-2 border rounded"
                required
              >
                <option value="">Select Author</option>
                {authors.map((author) => (
                  <option key={author.value} value={author.value}>
                    {author.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <Label htmlFor="categories">Categories</Label>
              <SimplifiedMultiSelect
                options={categories}
                value={step.categories || []}
                onChange={(value) => handleFieldChange('categories', value)}
                placeholder="Select Categories"
              />
            </div>

            <div>
              <Label htmlFor="tags">Tags</Label>
              <SimplifiedMultiSelect
                options={tags}
                value={step.tags || []}
                onChange={(value) => handleFieldChange('tags', value)}
                placeholder="Select Tags"
              />
            </div>
          </>
        ) : (
          <div>
            <Label htmlFor="json-reference">JSON Reference</Label>
            <Input
              id="json-reference"
              placeholder="JSON Reference (e.g., {{previousStepOutput}})"
              value={step.jsonReference || ''}
              onChange={(e) => handleFieldChange('jsonReference', e.target.value)}
              required
            />
          </div>
        )}

        

<div>
                <Label htmlFor="featured-image">Featured Image URL</Label>
                <Input
                  id="featured-image"
                  placeholder="Enter featured image URL"
                  value={step.featuredImage || ''}
                  onChange={(e) => handleFieldChange('featuredImage', e.target.value)}
                />
                <p className="mt-1 text-xs text-gray-500">
                  Enter the URL of the image you want to set as the featured image for this post.
                </p>
              </div>

        <div>
          <Label htmlFor="datePicker">Schedule Post (optional)</Label>
          <DatePicker
            selected={scheduledDate}
            onChange={handleDateChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="MMMM d, yyyy h:mm aa"
            className="w-full p-2 border rounded ml-2"
            placeholderText="Select date and time"
          />
        </div>

        <div>
          <Label htmlFor="post-status">Post Status</Label>
          <select
            id="post-status"
            value={step.postStatus || 'draft'}
            onChange={(e) => handleFieldChange('postStatus', e.target.value)}
            className="w-full p-2 border rounded"
            required
          >
            <option value="draft">Draft</option>
            <option value="publish">Publish</option>
          </select>
        </div>
      </CardContent>
    </Card>
  );
} 

const renderSaveAssetFields = () => {
  const handleTagsChange = (e) => {
    const tagsString = e.target.value;
    const tagsArray = tagsString.split(',').map(tag => tag.trim());
    handleFieldChange('assetTags', tagsArray);
  };

  const handleImageUrlsChange = (e) => {
    const urlsString = e.target.value;
    const urlsArray = urlsString.split(',').map(url => url.trim());
    handleFieldChange('imageUrls', urlsArray);
  };

  return (
    <Card>
      <CardContent className="pt-6 space-y-4">
        <div>
          <Label htmlFor={`asset-type-${index}`} className="text-inter font-bold">Asset Type</Label>
          <select
            id={`asset-type-${index}`}
            value={step.assetType || ''}
            onChange={(e) => handleFieldChange('assetType', e.target.value)}
            className="w-full p-2 border rounded"
            required
          >
            <option value="">Select Asset Type</option>
            {savableAssets.map((asset) => (
              <option key={asset.value} value={asset.value}>
                {asset.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <Label htmlFor={`asset-data-${index}`} className="text-inter font-bold">Main Content*</Label>
          <Textarea
            id={`asset-data-${index}`}
            placeholder={`{ text: "This is an example of a saved general text. It can be used for debugging purposes." }
            or
{ text: "This is an example of a saved general text. It can be used for debugging purposes.",
  title: "Example Record for Debugging",
  tags: ["debug", "example", "mongoose"] }
            or
{{step1_outputKey}} <- Notice the double brackets`}
            value={step.assetData || ''}
            onChange={(e) => handleFieldChange('assetData', e.target.value)}
            className="min-h-[200px]"
            required
          />
          <p className="mt-1 text-xs text-gray-500">
            Enter the asset data in JSON format. You can use placeholders like &#123;&#123;stepX_outputKey&#125;&#125; to reference outputs from previous steps.
          </p>
        </div>
        <div>
            <Label htmlFor={`image-urls-${index}`} className="text-inter font-bold">Image URLs (Optional)</Label>
            <Input
              id={`image-urls-${index}`}
              placeholder="Enter image URLs separated by commas"
              value={Array.isArray(step.imageUrls) ? step.imageUrls.join(', ') : step.imageUrls || ''}
              onChange={handleImageUrlsChange}
            />
            <p className="mt-1 text-xs text-gray-500">
              Enter image URLs separated by commas, e.g., "http://example.com/image1.jpg, http://example.com/image2.jpg"
            </p>
          </div>
        <div>
          <Label htmlFor={`asset-title-${index}`} className="text-inter font-bold">Title (Optional)</Label>
          <Input
            id={`asset-title-${index}`}
            placeholder="Enter asset title"
            value={step.assetTitle || ''}
            onChange={(e) => handleFieldChange('assetTitle', e.target.value)}
          />
        </div>
        <div>
          <Label htmlFor={`asset-tags-${index}`} className="text-inter font-bold">Tags (Optional)</Label>
          <Input
        id={`asset-tags-${index}`}
        placeholder="Enter tags separated by commas"
        value={Array.isArray(step.assetTags) ? step.assetTags.join(', ') : step.assetTags || ''}
        onChange={handleTagsChange}
      />
          <p className="mt-1 text-xs text-gray-500">
            Enter tags separated by commas, e.g., "tag1, tag2, tag3"
          </p>
        </div>
        <div>
          <Label htmlFor={`asset-note-${index}`} className="text-inter font-bold">Note (Optional)</Label>
          <Textarea
            id={`asset-note-${index}`}
            placeholder="Enter a note for this asset"
            value={step.note || ''}
            onChange={(e) => handleFieldChange('note', e.target.value)}
            className="min-h-[100px]"
          />
        </div>
        <div>
          <Label htmlFor={`output-key-${index}`} className="text-inter font-bold">Output Key</Label>
          <Input
            id={`output-key-${index}`}
            placeholder="e.g., savedAssetId"
            value={step.outputKey || ''}
            onChange={(e) => handleFieldChange('outputKey', e.target.value)}
            required
          />
        </div>
      </CardContent>
    </Card>
  );
};

switch (step.type) {
  case ActionStepType.POST_TO_WORDPRESS:
    return renderPostToWordPressFields();
  case ActionStepType.SAVE_ASSET:
    return renderSaveAssetFields();
  default:
    return null;
}
});

export default ActionStepFields;